.App-header {
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #333;
}

.question-title {
  font-size: 20px;
  font-weight: 600;
  color: #0082b9;
}

.question-answers {
  font-size: 16px;
}

.customico li {
  list-style-type: none;
}
.customico li:before {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  content: "";
  background: url(https://secureservers.be/vapz/assets/img/logo-symbool-omega.ico);
  background-size: 100%;
}

p {
  @apply py-2;
  font-size: 1rem;
}

.button-primary {
  @apply text-sm font-medium uppercase bg-tertiary text-white rounded-3xl py-2 px-6 cursor-pointer;
}

.header-primary {
  @apply text-4xl uppercase font-medium mb-4;
}

.header-secondary {
  @apply text-4xl font-medium mb-4;
}

.progress-bar {
  @apply fixed top-0 left-0 right-0 h-2 bg-tertiary;
  transform-origin: 0%;
}

.signature-field-section {
}
